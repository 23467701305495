import React from "react";
import * as Routes from "../../routes";
import { Link } from "react-router-dom";
import styles from "./ProjectListItem.module.scss";

const ProjectListItem = ({ id, title, subtitle, image, external }) => {
  return (
    <li
      className={styles.card}
      style={{ backgroundImage: `url("${process.env.PUBLIC_URL + image}")` }}
    >
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.subtitle}>{subtitle}</p>
        <Link
          className={styles.button}
          to={Routes.PROJECT_DETAILPAGE.replace(":title", title)}
        >
          Learn more
        </Link>
      </div>
    </li>
  );
};

export default ProjectListItem;
