import "./App.scss";

import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import * as Routes from "./routes";

import { HomePage, Projects, Contact, ProjectDetailPage } from "./pages";
import { ScrollToTop } from "./components/scrollTop";

function App() {
  useEffect(() => {
    if (History.scrollRestoration) {
      History.scrollRestoration = "manual";
    } else {
      window.onbeforeunload = function () {
        window.scrollTo(0, 0);
      };
    }
  }, []);

  return (
    <div>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <ScrollToTop>
          <Switch>
            <Route exact path={Routes.LANDING} component={HomePage} />
            <Route exact path={Routes.PROJECTS} component={Projects} />
            <Route
              exact
              path={Routes.PROJECT_DETAILPAGE}
              component={ProjectDetailPage}
            />
            <Route exact path={Routes.CONTACT} component={Contact} />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
}

export default App;
