import { Header } from '../components/Header';
import styles from './Projects.module.scss'

import React, { useEffect } from 'react';
import { OverlayLoader } from '../components/OverlayLoader';
import { ProjectList } from '../components/Projects';
import { pageAnimations } from '../components/Animations/Animations';

const Projects = () => {
  useEffect(() => {
    pageAnimations();

  }, []);

  return (
    <div className={styles.main} id="main">
      <OverlayLoader />
      <Header />
      <ProjectList />
    </div>
  )
}

export default Projects
