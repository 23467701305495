import React from "react";
import styles from "./OverlayLoader.module.scss";

const OverlayLoader = () => {
  return (
    <>
      <div className={styles.overlay}>
        <div className={`${styles.top} top`}>
          <div className={`${styles.overlayTop} overlay-top`}></div>
          <div className={`${styles.overlayTop} overlay-top`}></div>
          <div className={`${styles.overlayTop} overlay-top`}></div>
        </div>
      </div>
    </>
  );
};

export default OverlayLoader;
