import { withRouter } from 'react-router';

const ScrollToTop = (props, prevProps) => {
  
  if (props.location !== prevProps.location) {
    window.scrollTo(0, 0)
  }

  return (
    props.children
  )
}

export default withRouter(ScrollToTop)
