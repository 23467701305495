import React, { useEffect, useRef } from 'react'
import { pageAnimations } from '../components/Animations/Animations';
import { Header } from '../components/Header';
import { OverlayLoader } from '../components/OverlayLoader';
import styles from './Contact.module.scss';
import emailjs from 'emailjs-com';
import { emailjsConfig } from '../config';
import { AiOutlineMail, AiOutlinePhone, AiOutlineGithub, AiOutlineLinkedin } from 'react-icons/ai';

const Contact = () => {
  useEffect(() => {
    pageAnimations();

  }, []);

  const form = useRef();

  function sendEmail(e) {
    e.preventDefault();

  emailjs.sendForm(emailjsConfig.serviceId, emailjsConfig.templateId, form.current, emailjsConfig.userId)
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  }

  return (
    <div className={styles.main}>
      <OverlayLoader />
      <Header />

      <div className={styles.container}>
        <h1>
          Hello!
          <br/> 
          <span>Contact me using the form below.</span>
        </h1>

        <form ref={form} onSubmit={sendEmail}>
            <div>
                <input type="text"  name="user_name" required />
                <label htmlFor="user_name">Username</label>
                <span className={styles.inputBorder}></span>
            </div>
            <div>
                <input type="text"  name="user_email" required />
                <label htmlFor="user_email">Email</label>
                <span className={styles.inputBorder}></span>
            </div>
            <div>
                <textarea name="message" rows="5" required/>
                <label htmlFor="message">Message</label>
            </div>
            <input className={styles.formButton} type="submit" value="Send" />
        </form>

        <div className={styles.contactDetails}>
          <h2>Contact</h2>
          <ul>
            <li>
              <a href="tel:+324 91 55 20 66">
                <span className={styles.icon}><AiOutlinePhone /></span>
                <span className={styles.info}>+324 91 55 20 66</span>
              </a>
              <a href="mailto:jamie-lee.hart@hotmail.com">
                <span className={styles.icon}><AiOutlineMail /></span>
                <span className={styles.info}>jamie-lee.hart@hotmail.com</span>
              </a>
            </li>
          </ul>
          <h2>Socials</h2>
          <ul>
            <li>
              <a href="https://github.com/pgm-jamihart">
                <span className={styles.icon}><AiOutlineGithub /></span>
                <span className={styles.info}>Github</span>
              </a>
              <a href="https://www.linkedin.com/in/jamie-lee-hart-272b08100/">
                <span className={styles.icon}><AiOutlineLinkedin /></span>
                <span className={styles.info}>LinkedIn</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

    </div>
  )
}

export default Contact
