import React, { useEffect } from "react";
import projects from "../data/_data.json";
import { Header } from "../components/Header";
import styles from "./ProjectDetailPage.module.scss";
import { OverlayLoader } from "../components/OverlayLoader";
import { pageAnimationsDetail } from "../components/Animations/Animations";

import {
  ProjectDetail,
  ProjectTechnologies,
} from "../components/ProjectDetail";

const ProjectDetailPage = (props) => {
  const title = props.match.params.title;
  const selectedProject = projects.find((p) => p.title === title);

  useEffect(() => {
    pageAnimationsDetail();
  }, []);

  return (
    <div className={styles.main}>
      <OverlayLoader />
      <Header />

      <div className={styles.contentDetail} id="contentDetail">
        <ProjectDetail {...selectedProject} />
        <ProjectTechnologies technologies={selectedProject.technologie} />
      </div>
    </div>
  );
};

export default ProjectDetailPage;
