import React, { useState } from 'react';
import styles from './Header.module.scss';
import { Link } from "react-router-dom";
import { HamburgerMenu } from '../HamburgerMenu';
import * as Routes from '../../routes';

const Header = () => {
  const [activeMenu, setActiveMenu] = useState(false);
  const handleMenuClick = () => {
    disableMenu();
    setActiveMenu(!activeMenu)
  }
  
  // Prevents spamming the menu button so the animation wont break 
  const [preventSpam, setPreventSpam] = useState(false);
  const disableMenu = () => {
    setPreventSpam(!preventSpam);
    setTimeout(() => {
      setPreventSpam(false);
    }, 1000)
  }


  return (
    <div className={styles.header} id="header">
      <span className={styles.border} id="border"></span>
      <div className={styles.flex_container}>
        <div className={styles.vertical_center}>
          <div className={styles.logo} id="logo">
          <h1><Link to={Routes.LANDING}>Portfolio</Link></h1>
          </div>

          <button className={styles.nav} onClick={handleMenuClick} disabled={preventSpam}>
            <span></span>
            <span></span>
          </button>

          <HamburgerMenu value={activeMenu}/>

        </div>
      </div> 
    </div>
  )
}

export default Header
