import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import styles from './HamburgerMenu.module.scss';
import gsap from 'gsap';
import * as Routes from '../../routes';

import {
  menuOpenEffect,
  menuCloseEffect,
  menuLinksEffect,
} from '../Animations/Animations'

const HamburgerMenu = ({ value }) => {
  let menu = useRef(null);
  let openMenu = useRef(null);
  let openMenuBg = useRef(null);
  let link1 = useRef(null);
  let link2 = useRef(null);
  let link3 = useRef(null);
  
  useEffect(() => {
    if (value === true) {
      // open menu
      gsap.to(menu, {
        duration: 0,
        css: { display: 'block' }
      })
      menuOpenEffect([openMenu, openMenuBg]);
      menuLinksEffect([link1, link2, link3])
    } else if (value === false) {
      // close menu
      menuCloseEffect([openMenu, openMenuBg]) 
      gsap.to(menu, {
        duration: 1,
        css: { display: 'none' }
      });
    }
  }, [value]);


  return (
    <div className={styles.menu} ref={el => (menu = el)}>
      <div className={styles.menuBg} ref={el => (openMenuBg = el)}></div>
      <div className={styles.menuOverlay} ref={el => (openMenu = el)}>
        <div className={styles.navContainer}>
          <div className={styles.navWrapper}>
            <div className={styles.navLinks}>
              <nav>
                <ul>
                  <li><Link to={Routes.LANDING} ref={el => (link1 = el)} >Home</Link></li>
                  <li><Link to={Routes.PROJECTS} ref={el => (link2 = el)} >Projects</Link></li>
                  <li><Link to={Routes.CONTACT} ref={el => (link3 = el)} >Contact</Link></li>
                </ul>
              </nav>
            
            </div>
          </div>
        </div>
      </div>    
    </div>
  )
}

export default HamburgerMenu
