import React from "react";
import styles from "./ProjectTechnologies.module.scss";

const ProjectTechnologies = ({ technologies }) => {
  return (
    <div className={styles.container}>
      <div className={styles.containerTechnologie}>
        <ul className={styles.techIcons}>
          {!!technologies &&
            technologies.map((technologie) => {
              return (
                <li key={technologie.id} className={styles.box}>
                  <img
                    className={styles.boxImage}
                    src={process.env.PUBLIC_URL + technologie.image}
                    alt={technologie.title}
                  />
                  <span className={styles.boxTitle}>{technologie.title}</span>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default ProjectTechnologies;
