import { Hero } from "../components/Hero";
import { OverlayLoader } from "../components/OverlayLoader";
import { Header } from "../components/Header";

import React, { useEffect } from "react";
import { homepageAnimations } from "../components/Animations/Animations";

const HomePage = () => {
  const saveToStorage = () => sessionStorage.setItem("page-loaded", true);
  const pageLoaded = sessionStorage.getItem("page-loaded");

  useEffect(() => {
    if (pageLoaded) {
      homepageAnimations();
    } else {
      saveToStorage();
      homepageAnimations();
    }
  });

  return (
    <div>
      {!pageLoaded && <OverlayLoader />}
      <Header />
      <Hero />
    </div>
  );
};

export default HomePage;
