import React from "react";
import { AiOutlineGithub, AiOutlineLink } from "react-icons/ai";
import { Link } from "react-router-dom";
import styles from "./ProjectDetail.module.scss";

const ProjectDetail = ({
  title,
  description,
  github,
  briefing,
  video,
  producedBy,
  mockup1,
  deployment,
  hero_image,
}) => {
  return (
    <>
      <div className={styles.back}>
        <Link to="/projects">
          <span>Back to projects</span>
        </Link>
      </div>

      <div className={styles.containerInfo}>
        {hero_image ? (
          <img
            src={process.env.PUBLIC_URL + hero_image}
            className={styles.hero_media}
            alt="hero_image"
          />
        ) : (
          <video
            className={styles.hero_media}
            v
            width="100%"
            loop
            muted
            autoPlay
            controls
          >
            <source src={process.env.PUBLIC_URL + video} type="video/mp4" />
          </video>
        )}

        <div className={styles.overview}>
          <h1>{title}</h1>
          <p>{description}</p>
          <div className={styles.flexContainer}>
            {github && (
              <a href={github}>
                <AiOutlineGithub className={styles.icon} />
                <span>Github</span>
              </a>
            )}

            {deployment && (
              <a href={deployment}>
                <AiOutlineLink className={styles.icon} />
                <span>Website</span>
              </a>
            )}
          </div>
        </div>

        <div className={styles.test}>
          <div className={styles.briefing}>
            <span>{briefing}</span>
          </div>

          {mockup1 && (
            <div className={styles.image_mockup}>
              <img src={process.env.PUBLIC_URL + mockup1} alt={title} />
            </div>
          )}

          <div className={styles.producedBy}>
            <h2>Developed by:</h2>
            <ul className={styles.producedByList}>
              {!!producedBy &&
                producedBy.map((person) => {
                  return (
                    <li key={person.id}>
                      <a href={person.gh_link}>{person.name}</a>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectDetail;
