import gsap from "gsap";

export const menuOpenEffect = (arg1, arg2) => {
  gsap.to([arg1, arg2], {
    duration: 0.6,
    height: '100%',
    ease: 'power3.inOut',
    stagger: {
      amount: -0.05
    }
  });
};

export const menuCloseEffect = (arg1, arg2) => {
  gsap.to([arg1, arg2], {
    duration: 0.8,
    height: 0,
    ease: 'power3.inOut',
    stagger: {
      amount: 0.02
    }
  });
};

export const menuLinksEffect = (arg1, arg2, arg3) => {
  gsap.from([arg1, arg2, arg3], {
    y: 120,
      ease: 'power4.out',
      delay: 0.3,
      skewY: 13,
      stagger: {
        amount: 0.4
      }
  });
};

export const homepageAnimations = () => {
  const timeline = gsap.timeline();
  
    timeline.set(document.body, {overflow: "hidden"})

    timeline.from('.maskLine span', 1.8, {
      y: 120,
      ease: 'power4.out',
      delay: 0.3,
      skewY: 13,
      stagger: {
        amount: 0.4
      }
    }).set(document.body, {
      overflow: "auto"
    }).to('.overlay-top', 1.6, {
      height: 0,
      ease: 'expo.inOut',
      stagger: 0.4,
      zIndex: 0
    }).to('.top', 1, {
      height: 0,
    }).to('#logo', 1, {
      x:0,
      opacity: 1,
      delay: -3,
    }).to('#border', 1, {
      width: "100%",
      delay: -2
    })
}

export const pageAnimations = () => {
  const timeline = gsap.timeline();  

  timeline.set(document.body, {overflow: "hidden"});

  timeline.set('.overlay-top', {
    backgroundColor: '#fff'
  }).to('.overlay-top', 1.1, {
    height: 0,
    ease: 'expo.inOut',
    stagger: 0.1,
    zIndex: 0,
    opacity: 0
  }).set(document.body, {
    overflow: "auto"
  }).to('.top', 1, {
    height: 0,
  }).to('#logo', 1, {
    x:0,
    opacity: 1,
    delay: -1.5,
  }).to('#border', 1, {
    width: "100%",
    delay: -1.5
  }).to('#header', 1, {
      backgroundColor: '#000'
  })
}

export const pageAnimationsDetail = () => {
  const timeline = gsap.timeline();

  timeline.set(document.body, {overflow: "hidden"});
  timeline.set('#logo', {
    x:0,
    opacity: 1,
  })

  timeline.set('.overlay-top', {
    backgroundColor: '#000',
  }).to('#header', 0, {
    backgroundColor: '#000'
  }).from('.overlay-top', 1.4, {
    height: 0,
    ease: 'expo.inOut',
    stagger: 0.1,
    zIndex: 0,
  }).to('.overlay-top', 1, {
    borderBottom: '30px solid #66fcf1',
    delay: -0.5
  }).set('#contentDetail', {
    opacity: 1,
  }).to('.top', 1, {
    height: 0,
  }).to('#border', 1, {
    width: "100%",
    delay: -1.5
  }).set(document.body, {
    overflow: "auto"
  })
}