import React from "react";
import styles from "./Hero.module.scss";
import CV from "../../assets/CV_Jamie_Lee_Hart.pdf";
import avatar from "../../assets/avatar.png";

const Hero = () => {
  // const CV = "/assets/CV_Jamie_Lee_Hart.pdf";
  // const avatar = "/assets/avatar.png";

  return (
    <section className={styles.main}>
      <div className={styles.container}>
        <div className={styles.flex}>
          <div className={styles.intro}>
            <div className={styles.content}>
              <div className={`${styles.mask} maskLine`}>
                <span>Hello!</span>
              </div>
              <div className={`${styles.mask} maskLine`}>
                <span>
                  I'm <p>Jamie-Lee Hart</p>
                </span>
              </div>
            </div>

            <div className={styles.description}>
              <span>
                I recently graduated from Artevelde with a degree in computer
                programming with a focus on design and front-end development.
              </span>
              <span>
                I'm interested to improve my creative, technical and analytical
                skills to become a well-rounded full stack developer.
              </span>
              <div className={styles.CTA}>
                <a
                  className={styles.btn}
                  href={CV}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Curriculum Vitae (PDF)
                </a>
              </div>
            </div>
          </div>

          <img className={styles.avatar} src={avatar} alt="Jamie-Lee Hart" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
